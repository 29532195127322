import { components } from "@classdojo/ts-api-types/api";
import callApi from "@web-monorepo/infra/callApi";
import { makeApiMutation, makeMemberQuery, makeMutation } from "@web-monorepo/shared/reactQuery";
import { useStudentsFetcher } from "app/pods/student";
import { getLocalTokenData } from "app/pods/tokenLogin";
import { useCallback } from "react";

export const ACCOUNT_SWITCHER_KEY = "accountSwitcher";

interface ValidateGoogleCodeOperationParams {
  code: string;
  state: string;
}

export const useValidateGoogleCodeOperation = makeMutation<
  ValidateGoogleCodeOperationParams,
  components["schemas"]["DojoSessionResponse"]
>({
  name: "validateGoogleCode",
  fn: async ({ code, state }) => {
    try {
      return (
        await callApi({
          method: "POST",
          path: "/api/session",
          body: {
            googleToken: code,
            state,
          },
        })
      ).body;
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (err) {
      return err;
    }
  },
});

export const useGoToGoogleAuthOperation = makeMutation<Record<string, never>, { url: string }>({
  name: "goToGoogleAuth",
  fn: async () => {
    const response = await callApi({
      method: "GET",
      path: "/api/studentGoogleLoginUrl",
    });

    window.location = response.body.url;

    return response.body;
  },
});

interface LinkStudentWithGoogleAccountOperationParams {
  studentUserId: string;
  classTextCode: string;
  studentId: string;
}

export const useLinkStudentWithGoogleAccountOperation = makeMutation<LinkStudentWithGoogleAccountOperationParams, void>(
  {
    name: "linkStudentWithGoogle",
    fn: async ({ studentUserId, classTextCode, studentId }) => {
      try {
        return await callApi({
          method: "POST",
          path: `/api/studentUser/${studentUserId}/student`,
          body: {
            classTextCode,
            studentId,
          },
        });
        // eslint-disable-next-line no-catch-all/no-catch-all
      } catch (err: any) {
        return err;
      }
    },
    onSuccess: () => {
      useStudentsFetcher.invalidateQueries();
    },
  },
);

export const useLogOutOperation = makeMutation<Record<string, never>, void>({
  name: "logOutOperation",
  fn: async () => {
    const onLogOutDone = () => {
      const { token } = getLocalTokenData();

      // Do a page reload to make sure no redux or saga state persists from one user to the next
      if (token) {
        window.location.replace(`/#/tokenLogin?token=${token}`);
        window.location.reload();
      } else {
        window.location.replace("/#/login");
        window.location.reload();
      }
    };

    try {
      await callApi({
        path: `/api/session`,
        method: "DELETE",
      });
      onLogOutDone();
    } catch (error: any) {
      // Even if we get a 401 we wanted to log out so lets go to
      // login page
      if (error.response?.status === 401) {
        onLogOutDone();
      } else {
        throw error;
      }
    }
  },
  onSuccess: () => {
    useSessionFetcher.invalidateQueries();
  },
});

interface StudentLogoutOperationParams {
  redirect?: () => void;
}

// This endpoint only logs out the student, not any logged in P/T
export const useStudentLogoutOperation = makeMutation<StudentLogoutOperationParams, void>({
  name: "studentLogoutOperation",
  fn: async ({ redirect } = {}) => {
    try {
      await callApi({
        method: "POST",
        path: `/api/sessionEntityDelete`,
      });

      if (redirect) {
        redirect();
      }

      return;
    } catch (error: any) {
      if (error.response?.status < 500) {
        if (redirect) {
          redirect();
        }

        return;
      }
      throw error;
    }
  },
});

export const useSessionFetcher = makeMemberQuery({
  fetcherName: "sessionFetcher",
  path: "/api/session",
  query: { includeExtras: "location" },
  dontThrowOnStatusCodes: [401],
  onSuccess: (data) => {
    const studentId = data?.student?._id;
    if (studentId) {
      useStudentsFetcher.invalidateQueries({ studentUserId: studentId });
    }
  },
});

const _useModifySession = makeApiMutation({
  name: "modifySession",
  path: "/api/session",
  method: "patch",
});

export const useModifySession = () => {
  const { mutate: modifySession } = _useModifySession({});
  return {
    makeSessionReadOnly: useCallback(() => modifySession({ body: { readOnly: true } }), [modifySession]),
    makeSessionWritable: useCallback(() => modifySession({ body: { readOnly: false } }), [modifySession]),
  };
};

import "extended-proptypes/lib/extend-from-standalone";
// throw error for failling PropTypes checks
import "@web-monorepo/shared/consoleOverrides/throwPropTypesErrors";
import "@web-monorepo/shared/consoleOverrides/ignoreReactLifeCycleWarnings";
import "app/initialize";
import { MatomoConfiguredProvider } from "app/utils/MatomoConfiguredProvider";
import { NessieThemeProvider } from "@classdojo/web/nessie";
import { ErrorHandlerContext } from "@classdojo/web/pods/errorHandling";
import AppTopLevelErrorBoundary from "@classdojo/web/pods/errorHandling/components/AppTopLevelErrorBoundary";
import ErrorBanner from "@classdojo/web/pods/errorHandling/components/ErrorBanner";
import { ProductAreaContextProvider } from "@classdojo/web/pods/errorHandling/productArea";
import { UserActionsHistory } from "@classdojo/web/pods/userActionsHistory";
import { ReactQueryProvider } from "@web-monorepo/shared/reactQuery/provider";
import {
  scheduleRefresh,
  addMetaTagToRefreshNearMidnight,
  refreshIfOldVersion,
} from "@web-monorepo/shared/utils/midnightRefresh";
import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { createHomeIslandsWebErrorHandler, createWebErrorHandler } from "app/errorHandling";
import { DevTools } from "app/pods/devTools";
import { setAppFeatureSwitchesConfig } from "app/pods/featureSwitches/config";
import createStore from "app/store";
import env from "app/utils/env";
import { useOnFirstRender } from "@web-monorepo/hooks";
import { ThemesSection } from "@web-monorepo/shared/devTools/ThemesSection";
import { ColorThemeContext, useColorTheme } from "@classdojo/web/nessie/components/useColorTheme";
import { createRoutesFromChildren, matchRoutes, Outlet, useLocation, useNavigationType } from "react-router-dom";
import FixBadPaths from "@web-monorepo/shared/router/FixBadPaths";
import { RouterNavigateProvider } from "@web-monorepo/shared/router/reactRouterNavigate";
import * as Telemetry from "@web-monorepo/telemetry";
import { DesignSystemVariables, DDSScreenReaderAnnouncer, DDSProvider } from "@web-monorepo/dds";
import ApplicationContainer from "#/app/shared/components/ApplicationContainer";

setAppFeatureSwitchesConfig();

scheduleRefresh("student");
refreshIfOldVersion({ buildAgeInDays: 3, releaseAgeInDays: 1 });

//
// Initialize Datadog RUM
// https://www.datadoghq.com/dg/real-user-monitoring/overview/
//
// if (Config.nodeEnv === "production") {
//   initializeDatadogRUMForStudent();
// }

const userActionsHistory = new UserActionsHistory();
//
// Register browser history changes in our 'user actions module'
// We are calling this code 'early' and only once. To ensure we track any navigation or url change that happens
// even before we mount the top component in the app.
userActionsHistory.listenToBrowserUrlChanges();

if (Config.nodeEnv === "production") {
  Telemetry.init({
    dsn: "https://2840fbcf8d88d5c21359827600452f89@sentry.classdojo.com/14",
    tracing: {
      reactRouter: {
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      },
    },
    replays: {
      onErrorSampleRate: 0.15,
    },
  });
}

export default function Root() {
  const [errorHandler] = useState(() =>
    window.HOME_ISLANDS_EMBEDDED_IN_APP
      ? createHomeIslandsWebErrorHandler(userActionsHistory)
      : createWebErrorHandler(userActionsHistory),
  );

  const [{ store }] = useState(() =>
    // Build the redux store with all the reducers configured + middlewares.
    createStore(errorHandler, userActionsHistory),
  );

  useOnFirstRender(() => {
    addMetaTagToRefreshNearMidnight();
  });

  // We're setting the `document.domain` so we can access the playcanvas' iframe information.
  // https://stackoverflow.com/questions/22920081/access-subdomain-iframe-url-from-other-subdomain-page
  if (env.isDev) {
    try {
      document.domain = "classdojo.test";
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.warn("Failed to set document.domain in dev mode", ex);
    }
  }
  const { selectedTheme, mode, setNewTheme } = useColorTheme();
  return (
    <Provider store={store}>
      <DesignSystemVariables theme="light" />
      <DDSScreenReaderAnnouncer />
      <RouterNavigateProvider />
      <FixBadPaths />

      <ReactQueryProvider>
        <ErrorHandlerContext.Provider value={errorHandler}>
          <ProductAreaContextProvider>
            <DDSProvider>
              <ColorThemeContext.Provider value={{ setNewTheme, mode }}>
                <NessieThemeProvider selectedTheme={selectedTheme}>
                  <ErrorBanner />
                  <AppTopLevelErrorBoundary errorHandler={errorHandler}>
                    <MatomoConfiguredProvider>
                      <ApplicationContainer>
                        <Outlet />
                      </ApplicationContainer>
                      <DevTools
                        additionalSections={{
                          Themes: { title: "Themes", component: ThemesSection },
                        }}
                      />
                    </MatomoConfiguredProvider>
                  </AppTopLevelErrorBoundary>
                </NessieThemeProvider>
              </ColorThemeContext.Provider>
            </DDSProvider>
          </ProductAreaContextProvider>
        </ErrorHandlerContext.Provider>
      </ReactQueryProvider>
    </Provider>
  );
}

import * as logClient from "@classdojo/log-client";
import { LoadingMojo, useLoggerContext } from "@classdojo/web";
import useLogMarketingPageview from "@web-monorepo/shared/utils/useLogMarketingPageview";
import { FC, PropsWithChildren } from "react";
import I18NContainer from "app/I18NContainer";
import { useSessionFetcher } from "app/pods/auth";
import { useEnsureFeatureSwitches, useFetchedFeatureSwitchesWithOverrides } from "app/pods/featureSwitches";
import { SCHOOL_FEATURE_SWITCHES } from "app/pods/featureSwitches/constants";
import { useFetchedStudent } from "app/pods/student";
import useWatch from "@classdojo/web/hooks/useWatch";
import pubnub from "app/pubnub";

/* eslint-disable-next-line @web-monorepo/no-css-file-imports */
import "@classdojo/web/pods/font/dojoText.css";
/* eslint-disable-next-line @web-monorepo/no-css-file-imports */
import "@web-monorepo/dojo-islands/fonts/grandstander.css";
/* eslint-disable-next-line @web-monorepo/no-css-file-imports */
import "@web-monorepo/dojo-islands/fonts/omoType.css";
import { useLocation } from "react-router";
import { setUser } from "@web-monorepo/telemetry";

const ApplicationContainer: FC<PropsWithChildren> = ({ children }) => {
  const { ready: isFeatureSwitchesReady } = useEnsureFeatureSwitches();
  const location = useLocation();

  const { error: sessionError, data: session } = useSessionFetcher({});
  const hasFinishedFetchingSession = !!(sessionError || session);
  const student = session && session.student;

  useWatch(student, () => {
    if (student) {
      pubnub.init(student._id);
      logClient.setEntityId(student._id);
      //datadogRUMClient.setUser({ id: student._id });
    }

    setUser(student?._id ? { id: student?._id, type: "student" } : null);
  });

  useWatch([session, location?.pathname], () => {
    if (session?.loginMethod === "google" && session?.student?.students.length === 0) {
      // check for routes to ignore
      if (["studentOAuth2", "login"].some((str) => location?.pathname.includes(str))) {
        return;
      }

      // this is a google login student and it has no students connected yet,
      // so it somehow skipped the class code step. Send them there so they can
      // connect to a class as intended
      window.location.replace("/#/studentOAuth2Success?r=1");
    }
  });

  useLogMarketingPageview(hasFinishedFetchingSession);

  if (!hasFinishedFetchingSession || !isFeatureSwitchesReady) {
    return (
      <div>
        <LoadingMojo noOverlay border />
      </div>
    );
  }

  return (
    <I18NContainer locale={(student && student.locale) || null}>
      <EnsureLoggerContext>{children}</EnsureLoggerContext>
    </I18NContainer>
  );
};

export default ApplicationContainer;

const EnsureLoggerContext: FC<PropsWithChildren> = ({ children }) => {
  const student = useFetchedStudent();
  const fetchedFeatureSwitches = useFetchedFeatureSwitchesWithOverrides();
  const entityType = student ? "studentUser" : "preSignUp";

  // provide default school feature switch values of off for students that are not part of a school
  // and didnt fetch those switches
  Object.values(SCHOOL_FEATURE_SWITCHES).forEach((schoolFeatureSwitch) => {
    if (!fetchedFeatureSwitches[schoolFeatureSwitch]) {
      fetchedFeatureSwitches[schoolFeatureSwitch] = "off";
    }
  });

  useLoggerContext({ prefix: "student", entityType, fetchedFeatureSwitches });

  return <>{children}</>;
};
